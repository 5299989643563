<template>
    <div id="wellcome">
        <div>系统公告:{{ mes }}</div>
    </div>
</template>
<script>
export default {
    name: 'wellcome',
    //组件创建
    created() {
      //  this.getSys()
    },
    mounted() {
    },
    data() {
        return {
            mes: "",
        }
    },
    methods: {
        open() {
        this.$alert(this.mes, '系统公告', {
          confirmButtonText: '确定',
        })},
        getSys() {
            var that = this;
            this.$axios.post("/api/Sys/read", {
            })
                .then(function (response) {

                    console.log(response.data);
                    if (response.data.code == 200) {
                        console.log(response.data.mes);
                        that.mes = response.data.data.mes
                        that.open()
                    } else {
                        that.$message.error(response.data.mes);
                    }
                })
                .catch(function (error) {
                    that.$message.error('网络链接超时！');
                    console.log(error);
                });
        }
    }
}
</script>
<style >
.like {
    cursor: pointer;
    font-size: 25px;
    display: inline-block;
}
</style>