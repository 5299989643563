import Vue from 'vue'
import VueRouter from 'vue-router'
import Wellcome from '@/components/wellcome.vue'
import homeZp from '@/components/homeZp.vue'
import useradmin from '@/view/useradmin.vue'
import order from '@/view/order.vue'
import lou from '@/view/lou.vue'
import takeorder from '@/view/takeorder.vue'
import type from '@/view/type.vue'
import area from '@/view/area.vue'
import notice from '@/view/notice.vue'
import mypic from '@/view/mypic.vue'
import point from '@/view/point.vue'
import coupon from '@/view/coupon.vue'
import loginshow from '@/view/loginshow.vue'
// 引入加载条
import NProgress from 'nprogress'
import store from '../store/index'
import 'nprogress/nprogress.css'
// 配置
NProgress.inc()
NProgress.configure({
    easing: 'ease',  // 动画方式    
    speed: 800,  // 递增进度条的速度    
    showSpinner: false, // 是否显示加载ico    
    trickleSpeed: 200, // 自动递增间隔    
    minimum: 0.1 // 初始化时的最小百分比
})

Vue.use(VueRouter)

// 所有人都可以访问
const routes = [
    { path: '/', redirect: '/loginshow' },
    { path: '/takeorder', name: 'takeorder', component: takeorder },
    { path: '/loginshow', name: 'loginshow', component: loginshow, meta: { redirectAlreadyLogin: true } },
    // 访问home时，重定向到wellcome页
    {path: '/homeZp', name: 'homeZp', component: homeZp, redirect: '/order', meta: { title: 'Zp控制台' },
        children: [
            { path: '/point', name: 'point', component: point, },
            { path: '/coupon', name: 'coupon', component: coupon, },
            { path: '/lou', name: 'lou', component: lou, },
            { path: '/type', name: 'type', component: type, },
            { path: '/order', name: 'order', component: order },
            { path: '/useradmin', name: 'useradmin', component: useradmin },
            { path: '/notice', name: 'notice', component: notice },
            { path: '/mypic', name: 'mypic', component: mypic },
            { path: '/area', name: 'area', component: area },
        
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

/**
 * 挂载路由守卫
 * to 代表将要访问的路径
 * from 代表从哪个路径跳转而来
 * next 是一个函数，next() 放行。   next('/login') 强制跳转
 */
router.beforeEach((to, from, next) => {
    // 加载条
    NProgress.start()
    // if(to.name!=='loginshow' && !store.state.user.isLogin){
    //     alert("请先登陆！")
    //     next({name:'loginshow'})
    // }else if(to.meta.redirectAlreadyLogin && store.state.user.isLogin){
    //     next('/')
    // }else{
    // next();
    // }

    next();
    if (to.name == 'usersocket' && !store.state.user.isLogin) {
        alert("请先登陆！")
        next({ name: 'loginshow' })
    } else {
        next();
    }

})


// 挂载路由守卫 - 访问结束
router.afterEach(() => {
    // 关闭加载条
    NProgress.done()
})

export default router
