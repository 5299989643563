<template>
  <div class="context">

    <div class="cardheard">

<div style="margin-right: 15px; width:400px">
  <el-select  @change="changeName" v-model="areaId" placeholder="请选择校区">
        <el-option
          v-for="item in areas"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    <el-button @click="dialogresetVisible = true">新建宿舍楼栋</el-button>
  
</div>

</div>
    <template>
      <el-table v-model="Users" :data="Users" style="width: 100%" height="80%">
        <el-table-column type="index" width="50">
        </el-table-column>

        <el-table-column label="宿舍楼栋">
          <template slot-scope="scope">
            <span>{{ scope.row.buildName }}</span>
          </template>
        </el-table-column>


      
        <el-table-column label="操作" width="300">
 
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)" size="small">编辑</el-button>
            <el-button type="text"  @click="del(scope.row)" size="small">删除</el-button>
        </template>
        </el-table-column>
      </el-table>

      <div slot="footer" class="dialog-footer">
        <div class="block">
          <el-pagination @current-change="handleCurrentChange" background layout="prev, pager, next" :total="10000">
          </el-pagination>
        </div>
      </div>

      <el-dialog
      title="添加楼"
      :visible.sync="dialogresetVisible"
      width="30%"
      center
    >
      <el-form :model="newtable">
        <el-select v-model="newareaId" placeholder="请选择校区">
        <el-option
          v-for="item in areas"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
        <el-input v-model="newtable.buildName" placeholder="楼名称"></el-input>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogresetVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="修改"
      :visible.sync="updateVisible"
      width="30%"
      center
    >
      <el-form :model="updatetable">
        
        <el-input v-model="updatetable.buildName" placeholder="楼名称"></el-input>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updateVisible = false">取 消</el-button>
        <el-button type="primary" @click="update">确 定</el-button>
      </div>
    </el-dialog>


    </template>
    <template>
</template>
  </div>
</template>

<script>
import store from '@/store/'

export default {
  data() {
    return {
      req: {
        "pageIndex": 0,
        "pageCount": 10
      },
      areas:[],
      areaId:"",
      newareaId:"",
      newtable:{
        buildName:""
      },
      updatetable:{
        buildName:"",
        id:""
      },
      Users:[],
      dialogresetVisible:false,
      updateVisible:false,
    }
  },
  mounted() {
    this.getArea()
   this.pushdata()
  },
 
  methods: {
    changeName(){
      console.log(112)
      this.pushdata();
    },
    getArea(){
    var that = this;
      that.$axios
        .post("/api/admin/getArea", {
          "pageIndex": that.req.pageIndex,
          "pageCount": that.req.pageCount,
        })
        .then(function (response) {
          // that.$message.success({
          //   message: "数据已刷新~"
          // });
          that.areas = response.data.data
          if(that.areas){
            that.areaName=that.areas[0].name
            console.log( that.areaName)
          }

        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
   },
    edit(row){
    console.log(row);
      this.updatetable.id=row.id
      this.updatetable.buildName=row.buildName
      this.updateVisible=true

      console.log(this.updatetable);
    },

    update(){
      var that = this;
      that.$axios
        .post("/api/admin/updateBuild", {
          "id":that.updatetable.id,
          "buildName":that.updatetable.buildName
        })
        .then(function (response) {
          that.$message.success({
            message: response.data.mes
          });
          that.updateVisible=false
          that.pushdata()
        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
    },
    del(row){
      var that = this;
      that.$axios
        .post("/api/admin/delBuild", {
          "id":row.id,
        })
        .then(function (response) {
          that.$message.success({
            message: response.data.mes
          });
          that.dialogresetVisible=false
          that.newtable={}
          that.pushdata()
        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);

      if (val != 1) {
        var statcount = (val - 1) * this.req.pageCount
        this.req.pageIndex = statcount
        console.log("当前开始index--" + statcount);
      } else {
        this.req.pageIndex = 0
        console.log("当前开始index--" + statcount);
      }

      console.log(this.req);
      this.pushdata()
    },
    addUser(){
      var that = this;
      that.$axios
        .post("/api/admin/addBuild", {
          "buildName":that.newtable.buildName,
          "areaId":that.newareaId
        })
        .then(function (response) {
          that.$message.success({
            message: response.data.mes
          });
          that.dialogresetVisible=false
          that.newtable={}
          that.pushdata()
        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
    },
    pushdata(){
    var that = this;
      that.$axios
        .post("/api/admin/showBuild", {
          "pageIndex": that.req.pageIndex,
        "pageCount": that.req.pageCount,
        "areaId":that.areaId
        })
        .then(function (response) {
          // that.$message.success({
          //   message: "数据已刷新~"
          // });
          that.Users = response.data.data

        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
   }
  },
}
</script>
<style scoped></style>
