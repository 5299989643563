<template>
  <div class="context">

    <div class="cardheard">



</div>
    <template>

      <el-input
  type="textarea"
  :rows="12"
  placeholder="请输入内容"
  v-model="form.content">
</el-input>

     <el-button type="p" @click="update">保存</el-button>


    </template>
    <template>
</template>
  </div>
</template>

<script>
import store from '@/store/'

export default {
  data() {
    return {
      form:{
        content:"",
        id:"",
      },
     
      req: {
        "pageIndex": 0,
        "pageCount": 10
      },
      newtable:{
        pointname:"",
      },
      updatetable:{
        id:"",
        pointname:"",
      },
      Users:[],
      dialogresetVisible:false,
      updateVisible:false,
    }
  },
  mounted() {
   this.pushdata()
  },
 
  methods: {
    edit(row){
      console.log(row);
      this.updatetable.id=row.id
      this.updatetable.pointname=row.pointName
      this.updateVisible=true

      console.log(this.updatetable);
    },
    del(row){
      var that = this;
      that.$axios
        .post("/api/admin/delPoint", {
          "id":row.id,
        })
        .then(function (response) {
          that.$message.success({
            message: response.data.mes
          });
          that.dialogresetVisible=false
          that.newtable={}
          that.pushdata()
        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);

      if (val != 1) {
        var statcount = (val - 1) * this.req.pageCount
        this.req.pageIndex = statcount
        console.log("当前开始index--" + statcount);
      } else {
        this.req.pageIndex = 0
        console.log("当前开始index--" + statcount);
      }

      console.log(this.req);
      this.pushdata()
    },

    update(){
      var that = this;
      that.$axios
        .post("/api/admin/updateNotice", {
          "id":that.form.id,
          "content":that.form.content,
        })
        .then(function (response) {
          that.$message.success({
            message: response.data.mes
          });
          that.updateVisible=false
          that.pushdata()
        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
    },



    addPoint(){
      var that = this;
      that.$axios
        .post("/api/admin/addPoint", {
          "pointName":that.newtable.pointname,
        })
        .then(function (response) {
          that.$message.success({
            message: response.data.mes
          });
          that.dialogresetVisible=false
          that.newtable={}
          that.pushdata()
        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
    },
    pushdata(){
    var that = this;
      that.$axios
        .post("/api/admin/getNotice", {
          
        })
        .then(function (response) {
          // that.$message.success({
          //   message: "数据已刷新~"
          // });
          that.form = response.data.data

        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
   }
  },
}
</script>
<style scoped></style>
