import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import axios from "axios";

import '../node_modules/element-ui/lib/theme-chalk/index.css'

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
// 引入全局样式
import './assets/css/global.css'
// 引入字体图标
import 'font-awesome/css/font-awesome.min.css'
Vue.prototype.$axios = axios
//axios.defaults.baseURL = '/api'
//axios.defaults.baseURL = '/'
new Vue({
  axios,
  router,
  store,  //store:store 和router一样，将创建的Vuex实例挂载到这个vue实例中
  render: h => h(App)
}).$mount('#app')


