<template>
  <div class="box">

    <div class="login">



      <!-- <div class="logo">
        <div style="text-align: center;">
          <img src="../assets/logo3.png" width="130px" height="30px" alt="">
        </div>

        <div class="logotext" style="color:rgb(50,99,255)">藏聘</div>
      </div> -->
      <div style="margin: 20px;">
        <div class="content">
          <el-form :label-position="labelPosition" label-width="80px" :model="userdata" ref="userdata" :rules="rules">
            <el-form-item label="账号">
              <el-input prefix-icon="el-icon-phone-outline" v-model="userdata.phone"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input show-password prefix-icon="el-icon-lock" v-model="userdata.password"></el-input>
            </el-form-item>

          </el-form>
          <el-button :loading=loading style="width:98%;margin:20px auto" type="primary" @click="login('userdata')">登录<i
              class="el-icon-right"></i></el-button>
          <!-- <div @click="toregister()" style="display:block;width:100%;text-align: center; color: rgb(69, 172, 251);">
            没用账号？去注册</div> -->
        </div>
      </div>

    </div>
  </div>
</template>
  
<script>
import store from '../store/index'
export default {
  data() {

    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      }
      setTimeout(() => {
        if (!/(^\d{11}$)/.test(value)) {
          callback(new Error("手机号有误"));
        }
        else {
          callback();
        }
      }, 1000);
    };

    var checkPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };

    return {
      loading: false,//按钮加载状态
      labelPosition: 'right',
      userdata: {
        phone: "",
        password: "",
      },
      rules: {
        phone: [{ validator: checkPhone, trigger: "blur", required: true }],
        password: [{ validator: checkPassword, trigger: "blur", required: true }],
      },
    };
  },
  mounted() {

    this.$nextTick(() => {

      console.log("login");
      var info = JSON.parse(localStorage.getItem("info"))
      if (info) {
        this.userdata.phone = info.userid
        this.userdata.password = info.password
        console.log(info.userid);
      }

    })
  },
  methods: {

    // ispub() {
    //   var that = this;
    //   that.$axios
    //     .post("/api/Notes/FindNoteByPhone", {
    //       phone: this.userdata.phone,
    //     })
    //     .then(function (response) {
    //       that.loading = false
    //       console.log(response.data);
    //       if (response.data.code == 205) {
    //         //不存在简历数据
    //         that.$message.success("登录成功！");
    //         that.$router.push("/biographical");
    //       } else {
    //         that.$router.push("/success");
    //       }

    //     })
    //     .catch(function (error) {
    //       that.$message.error("服务器校验失败");
    //       that.loading = false
    //     });
    // },
    login(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var that = this;
          this.loading = true
          this.$axios.post("api/User/login", {
            userid: this.userdata.phone,
            password: this.userdata.password
          })
            .then(function (response) {
              that.loading = false
              console.log(response);

              if (response.data.code == 200) {
                var info = {
                  userid: that.userdata.phone,
                  password: that.userdata.password,
                  identity:response.data.data.identity
                };
                localStorage.setItem("info", JSON.stringify(info));
                store.state.user.userid=response.data.data.userid
                store.state.user.isLogin=true
                that.$router.push("/homeZp")
                // if (response.data.data.identity == 1) {
                //   that.$router.push("/homeZp");
                // } else {
                //   //如果存在表单则跳到成功界面
                //  // that.ispub()
                //  console.log("普通用户登陆");
                // }
              } else {
                that.$message.error(response.data.mes);
              }

            })
            // .catch(function (error) {
            //   that.$message.error('1');
            //   that.loading = false
            //   console.log(error);
            // });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    toregister() {
      this.$router.push("/registershow");
    },
    sendbiographical() {
      this.$router.push("/biographical");
    },
  },
};
</script>
  
<style lang="less" scoped>
.logotext {
  font-weight: 800;
  text-align: center;
  font-size: 70px;
}

.box {
  width: 100%;
  height: vh;
  position: absolute;

}

.login {

  position: relative;
}
</style>
  