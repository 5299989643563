<template>
  <div class="context">
    <el-carousel :interval="5000" arrow="always">
      <el-carousel-item v-for="item in piclist" :key="item.id">
        <!-- <h3>{{ item.name }}</h3> -->
        <div class="pic_item" @click="goUrl(item.url)">
          <!-- <img  referrerpolicy="no-referrer" class="small" :src="item.url" /> -->
          <el-image :src="item.url" referrerpolicy="no-referrer"  :preview-src-list="piclist.url"></el-image>
          <h3>{{ item.name }}</h3>
        </div>

      </el-carousel-item>
    </el-carousel>

    <div style="margin-top: 20px;">
      <el-select v-model="form.id" placeholder="请选择图片">
        <el-option v-for="val in piclist" :key="val.id" :value="val.id" :label="val.name"></el-option>

      </el-select>

      <el-button type="primary" @click="del">删除</el-button>
      <input class="file" name="file" type="file" accept="image/png,image/gif,image/jpeg" @change="update($event)" />

    </div>
  </div>
</template>

<script>
import store from '@/store/'
import { fileUploading } from '../utils/FileSubmit';  //  路径问题自己进行处理
export default {
  departmentsImport(param) {
    var that = this;
    fileUploading(param).then(response => {
      if (response.msg == 'success') {
        MessageBox({
          title: '提示',
          message: '上传成功',
          type: 'success'
        });
      } else {
        MessageBox({
          title: '提示',
          message: '文件上传失败，请稍后再试',
          type: 'error'
        })
      }
    })
  },
  data() {
    return {
      imageUrl: '',
      form: {
        name: "",
        id: ""
      },
      piclist: [],
      fileList: [],
      req: {
        "pageIndex": 0,
        "pageCount": 10
      },
      newtable: {
        pointname: "",
      },
      updatetable: {
        id: "",
        pointname: "",
      },
      Users: [],
      dialogresetVisible: false,
      updateVisible: false,
    }
  },
  mounted() {
    this.pushdata()
  },

  methods: {
    goUrl (url) {
      window.location.href = url
 },
    edit(row) {
      console.log(row);
      this.updatetable.id = row.id
      this.updatetable.pointname = row.pointName
      this.updateVisible = true

      console.log(this.updatetable);
    },
    del() {
      var that = this;
      that.$axios
        .post("/api/admin/deletePic", {
          "id": this.form.id
        })
        .then(function (response) {
          that.$message.success({
            message: response.data.mes
          });

          that.$router.go(0)
        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "删除失败"
          });
        });
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);

      if (val != 1) {
        var statcount = (val - 1) * this.req.pageCount
        this.req.pageIndex = statcount
        console.log("当前开始index--" + statcount);
      } else {
        this.req.pageIndex = 0
        console.log("当前开始index--" + statcount);
      }

      console.log(this.req);
      this.pushdata()
    },

    update(e) {
      var that = this;
      let file = e.target.files[0];
      let param = new FormData(); //创建form对象
      param.append('file', file, file.name);//通过append向form对象添加数据     
      //param.append('chunk','0');//添加form表单中其他数据
      //console.log(param.get('tweetPic')); //FormData私有类对象，访问不到，可以通过get判断值是否传进去
      let config = {
        headers: { 'Content-Type': 'multipart/form-data' }
      };  //添加请求头
      that.$axios
        .post("/api/minio/uploadFile", param, config)
        .then(function (response) {
          that.$message.success({
            message: response.data.mes
          });

          that.$router.go(0)
        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "上传失败"
          });
        });
    },



    addPoint() {
      var that = this;
      that.$axios
        .post("/api/admin/addPoint", {
          "pointName": that.newtable.pointname,
        })
        .then(function (response) {
          that.$message.success({
            message: response.data.mes
          });
          that.dialogresetVisible = false
          that.newtable = {}
          that.pushdata()
        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
    },
    pushdata() {
      var that = this;
      that.$axios
        .post("/api/admin/showAllPic", {

        })
        .then(function (response) {
          // that.$message.success({
          //   message: "数据已刷新~"
          // });
          that.piclist = response.data.data

        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
    }
  },
}
</script>
<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.pic_item h3 {
  font-size: 30px;
  color: red;
  position: absolute;
  left: 1rem;
  bottom: 2rem;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
</style>
