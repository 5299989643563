<template>
	<div>
		<button @click="hanedleWxPay" style="width:500px;height:500px">微信支付</button>
	</div>
</template>

<script>
import store from '@/store/'
import wxPay from "@/utils/wxPay";
import wx from 'weixin-jsapi'
export default {
	data() {
		return {
			data: {}

		}
	},
	mounted() {

	},

	methods: {
		onBridgeReady (data) {
      let self = this
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
          'appId': data.appId,//公众号名称，由商户传入     
          'timeStamp': String(data.timeStamp),    //时间戳，自1970年以来的秒数。这里必须要转换为字符串。ios跟android表现不同。后台返回的是数值，但是微信方面必须要json参数都是字符串形式，android会自动转换成字符串（当时我在这里也找了很久的博文才知道的）
          'nonceStr': data.nonceStr,//随机串     
          'package': data.package,
          'signType': data.signType,//微信签名方式：
          'paySign': data.paySign//微信签名 
        },
        function (res) {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {//支付成功
            self.$router.replace({name: 'paymentend'})
          } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
            $.toast('支付取消!','text');
          } else {
            $.toast('支付失败!','text');
          }
        }
      )
    },

		hanedleWxPay() {
			var that = this
			//首先要调后端一个接口，接口内返回值需要appid, nonce_str, sign, prepay_id, timestamp
			this.$axios.post('/api/pay/returnparam', {
				"price": 20,
				"goodsid": 1123123123123,
				"title": "这是一个测试的商品"
			}).then((res) => {
				console.log(res)
		    var data = {
					appId: res.data.appid,//公众号id，必传
					nonceStr: res.data.nonceStr,//随机串，必传
					timeStamp: res.data.timeStamp,//时间戳，必传
					package: res.data.package,//必传
					signType: res.data.signType,//微信签名方式
					paySign: res.data.paySign,//微信签名
				}

				
              let vm = this;
              if (typeof WeixinJSBridge === 'undefined') {
                if (document.addEventListener) {
                  document.addEventListener('WeixinJSBridgeReady', vm.onBridgeReady(data), false)
                } else if (document.attachEvent) {
                  document.attachEvent('WeixinJSBridgeReady', vm.onBridgeReady(data))
                  document.attachEvent('onWeixinJSBridgeReady', vm.onBridgeReady(data))
                }
              } else {
                vm.onBridgeReady(data)
              }



			})
		}


	},
}
</script>
<style scoped></style>
