<template>
  <div class="context">


    <div class="cardheard">

      <div style="margin-right: 15px; width:800px" class="myheard">

        <el-select @change="changeName" v-model="areaName" placeholder="请选择校区">
        <el-option
          v-for="item in areas"
          :key="item.id"
          :label="item.name"
          :value="item.name">
        </el-option>
      </el-select>

        <div>
          <el-date-picker v-model="startTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </div>


        <el-button type="success" @click="selectByTime">查询</el-button>

        <el-button @click="exportExcel" type="success">确认导出EXCEL</el-button>





      </div>
    </div>
    <!--搜索框-->
    <!-- <el-input style="width: 400px; margin: 20px 20px 20px 20px" v-model="inputValue" placeholder="请输入内容"
      prefix-icon="el-icon-search">
    </el-input> -->
    <!-- <el-button type="primary" @click="showadd" style="height: 40px; margin: 0px 0px 0px 20px">
      批量添加</el-button> -->

    <template>
      <el-table :data="Users" style="width: 100%" height="80%">
        <el-table-column label="序号" type="index" width="50">
        </el-table-column>
        <!-- <el-table-column label="ID">
          <template slot-scope="scope">
            <span>{{ scope.row.id }}</span>
          </template>
        </el-table-column> -->

        <el-table-column label="下单时间">
          <template slot-scope="scope">
            <span>{{ scope.row.time }}</span>
          </template>
        </el-table-column>


        <el-table-column label="快递点">
          <template slot-scope="scope">
            <span>{{ scope.row.point }}</span>
          </template>
        </el-table-column>

        <el-table-column label="取件信息">
          <template slot-scope="scope">
            <span>{{ scope.row.mess }}</span>
          </template>
        </el-table-column>

        <el-table-column label="快递类型">
          <template slot-scope="scope">
            <span>{{ scope.row.size }}</span>
          </template>
        </el-table-column>


        <el-table-column label="姓名">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>


        <el-table-column label="电话">
          <template slot-scope="scope">
            <span>{{ scope.row.phone }}</span>
          </template>
        </el-table-column>


        <el-table-column label="宿舍楼栋">
          <template slot-scope="scope">
            <span>{{ scope.row.lh }}</span>
          </template>
        </el-table-column>

        <el-table-column label="房间号">
          <template slot-scope="scope">
            <span>{{ scope.row.fh }}</span>
          </template>
        </el-table-column>

        <el-table-column label="微信名">
          <template slot-scope="scope">
            <span>{{ scope.row.wxname }}</span>
          </template>
        </el-table-column>



        <!-- 
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="looknote(scope.$index, scope.row)" size="small">查看</el-button>
           
            <el-button type="text" @click="showUserInfo(scope.row)" size="small">管理</el-button>
        
          </template>
        </el-table-column> -->


      </el-table>

    </template>

    <template>
      <el-dialog title="选择导出" :visible.sync="UserInfo" width="37%" center>
        <div style="height: 180px;">
          <el-form label-width="80px">


            <el-form-item label="选择时间区间:" label-width="200">
              <div class="block">
                <el-date-picker v-model="startTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
                </el-date-picker>

                <el-button type="success" @click="selectByTime">查询</el-button>

                <div class="home">
                  <el-button @click="exportExcel" type="success">确认导出EXCEL</el-button>
                </div>

              </div>
            </el-form-item>

          </el-form>



        </div>

      </el-dialog>
    </template>

    <div class="block">
      <el-pagination @current-change="handleCurrentChange" background layout="prev, pager, next" :total="1000">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      areaName:"",
      areas:[],
      phone: "",
      startTime: "",
      endTime: "",
      execlData: [],
      UsersCashRecoed: [],
      detailuserid: "",//当前用户id明细
      UsersDetailMoney: [],//收益明细
      inputMoney: "",//输入的余额
      userid: "",
      vipTime: "",//修改的VIP到期时间
      timestamp: "",//当前vip到期时间
      data: [],
      formLabelWidth: "120px",
      newtable: {
        header: "",
        begin: "",
        end: ""
      },
      detailmoney: false,//收益面板
      UserInfo: false,//用户详情面板
      cashRecord: false,//提现面板
      pagecount: 99,//查询的总数
      currentPage4: 4,
      Users: [],//用户数据数组
      UserInfoDetail: {
        userid: "",
        vip: "",
      },//单个用户信息
      req: {
        "pageIndex": 0,
        "pageCount": 10
      },
      req2: {
        "pageIndex": 0,
        "pageCount": 20
      },
      req3: {
        "pageIndex": 0,
        "pageCount": 20
      }
    };
  },

  created() {
    var that = this;
    console.log("Vue 初始化完成 created");
    this.getArea()

    setTimeout(() => {
      this.pushdata();
    }, 1000); // 1000毫秒即1秒
  

   
  },

  methods: {
    changeName(){
      console.log(112)
      this.pushdata();
    },
    getArea(){
    var that = this;
      that.$axios
        .post("/api/admin/getArea", {
          "pageIndex": that.req.pageIndex,
          "pageCount": that.req.pageCount,
        })
        .then(function (response) {
          // that.$message.success({
          //   message: "数据已刷新~"
          // });
          that.areas = response.data.data
          if(that.areas){
            that.areaName=that.areas[0].name
            console.log( that.areaName)
          }

        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
   },

    exportExcel() {

      if(this.startTime==''){
        this.$message.error("请输入日期");
        return
      }
      window.location.href = "http://118.89.70.213:8080/Record/selectExcelByTime?time=" + this.startTime+"&area="+this.areaName
      // var that = this;
      // this.$axios.get("/api/Record/selectExcelByTime", {
      //   "startTime": that.startTime,
      // })
      //   .then(function (response) {
      //     console.log(response.data);
      //     if (response.data.code == 200) {
      //       that.$message.success("查询成功！共有："+response.data.pageCount+"条数据");
      //       that.execlData=response.data.data
      //     } else {
      //       that.$message.error(response.data.mes);
      //     }
      //   })
      //   .catch(function (error) {
      //     that.$message.error('网络链接超时！');
      //     console.log(error);
      //   });
    },
    selectByTime() {

      if(this.startTime==""){
        this.pushdata()
        return
      }


      var that = this;
      this.$axios.post("/api/Record/selectByTime", {
        "pageIndex": 0,
        "pageCount": 10,
        "startTime": that.startTime,
        "areaName":that.areaName
      })
        .then(function (response) {
          console.log(response.data);
          if (response.data.code == 200) {
            that.$message.success("查询成功！共有：" + response.data.pageCount + "条数据");
            that.Users = response.data.data
          } else {
            that.$message.error(response.data.mes);
          }
        })
        .catch(function (error) {
          that.$message.error('网络链接超时！');
          console.log(error);
        });
    },
    download() {
      const data = XLSX.utils.json_to_sheet(this.items)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'kalacloud-data')
      XLSX.writeFile(wb, 'kalacloudExportExcel.xlsx')
    },
    changeViptime() {
      console.log(this.vipTime);
      var changeTime = ""
      if (this.vipTime == "") {
        changeTime = this.timestamp
      } else {
        changeTime = (this.vipTime).valueOf() / 1000;
      }

      var that = this;
      this.$axios.post("/api/User/UpdateUser", {
        "userid": that.UserInfoDetail.userid,
        "vip": changeTime,
        "money": that.inputMoney
      })
        .then(function (response) {
          console.log(response.data);
          if (response.data.code == 200) {
            that.$message.success(response.data.mes);
          } else {
            that.$message.error(response.data.mes);
          }
        })
        .catch(function (error) {
          that.$message.error('网络链接超时！');
          console.log(error);
        });
      that.UserInfo = false

      },
    timestampToTime(timestamp) {
      // 时间戳为10位需*1000，时间戳为13位不需乘1000
      var date = new Date(timestamp * 1000);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    },


    delcoin(index, row) {
      var that = this;
      that.$axios
        .post("/api/Money/delCoin", {
          userid: row.userid,
          money: row.money
        })
        .then(function (response) {
          console.log(response.data);
          that.$message.success({
            message: "操作成功"
          });
        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "操作失败"
          });
        });
    },
    selectOne() {
      if (this.phone != "") {
        this.selectById()
      } else {
        this.pushdata()
      }
    },
    selectById() {
      var that = this;
      this.$axios.post("/api/Record/selectAll", {
        "phone": that.phone,
        "pageIndex": that.req.pageIndex,
        "pageCount": that.req.pageCount,
      })
        .then(function (response) {

          console.log(response.data);
          if (response.data.code == 200) {
            that.Users = response.data.data
          } else {
            that.$message.error(response.data.mes);
          }
        })
        .catch(function (error) {
          that.$message.error('网络链接超时！');
          console.log(error);
        });
    },
    showadd() {
      this.dialogresetVisible = true
    },
    addusers() {

      for (let index = this.newtable.begin; index < this.newtable.end; index++) {
        var user = {
          uid: this.newtable.header + index,
          password: this.newtable.header
        }
        this.data.push(user)
      }
      var that = this;
      that.$axios
        .post("/api/User/addUser", {
          users: that.data
        })
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "添加失败"
          });
        });


    },
    next(a) {
      console.log(a);
    },
    reset() {
      this.req = {
        "pageIndex": 0,//每次请求数据的开始位置
        "pageCount": 10//每次请求数据的总量
      }
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);

      if (val != 1) {
        var statcount = (val - 1) * this.req.pageCount
        this.req.pageIndex = statcount
        console.log("当前开始index--" + statcount);
      } else {
        this.req.pageIndex = 0
        console.log("当前开始index--" + statcount);
      }

      console.log(this.req);
      this.pushdata()
    },

    pushdata() {
      var that = this;
      this.$axios.post("/api/Record/selectAll", {
        "pageIndex": that.req.pageIndex,
        "pageCount": that.req.pageCount,
        "areaName":that.areaName
      })
        .then(function (response) {

          console.log(response.data);
          if (response.data.code == 200) {
            that.Users = response.data.data
          } else {
            that.$message.error(response.data.mes);
          }
        })
        .catch(function (error) {
          that.$message.error('网络链接超时！');
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
.home {

  right: 0px;
  top: 150px;
  position: absolute;
}

.myheard {
  margin-left: 10px;
  display: flex;
}

.block {
  margin-top: 0px;
}
</style>
