<template>
  <div class="context">

    <div class="cardheard">

<div style="margin-right: 15px; width:400px">
    <el-button @click="dialogresetVisible = true">新建校区</el-button>
  
</div>

</div>
    <template>
      <el-table v-model="Users" :data="Users" style="width: 100%" height="80%">
        <el-table-column label="序号" type="index" width="50">
        </el-table-column>

        <el-table-column label="校区名称">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>



        <el-table-column label="操作" width="300">
 
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="looknote(scope.$index, scope.row)" size="small">查看</el-button> -->
            <el-button type="text" @click="edit(scope.row)" size="small">编辑</el-button>
            <el-button type="text"  @click="del(scope.row)" size="small">删除</el-button>
        </template>
        </el-table-column>
      </el-table>

      <div slot="footer" class="dialog-footer">
        <div class="block">
          <el-pagination @current-change="handleCurrentChange" background layout="prev, pager, next" :total="10000">
          </el-pagination>
        </div>
      </div>

      <el-dialog
      title="添加快递点"
      :visible.sync="dialogresetVisible"
      width="30%"
      center
    >
      <el-form :model="newtable">
        
        <el-input v-model="newtable.pointname" placeholder="快递点名称"></el-input>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogresetVisible = false">取 消</el-button>
        <el-button type="primary" @click="addPoint">确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog
    title="修改"
    :visible.sync="updateVisible"
    width="30%"
    center
  >
    <el-form :model="updatetable">
      
      <el-input v-model="updatetable.pointname" placeholder="校区名称"></el-input>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="updateVisible = false">取 消</el-button>
      <el-button type="primary" @click="update">确 定</el-button>
    </div>
  </el-dialog>



    </template>
    <template>
</template>
  </div>
</template>

<script>
import store from '@/store/'

export default {
  data() {
    return {
      req: {
        "pageIndex": 0,
        "pageCount": 10
      },
      newtable:{
        name:"",
      },
      updatetable:{
        id:"",
        pointname:"",
      },
      Users:[],
      dialogresetVisible:false,
      updateVisible:false,
    }
  },
  mounted() {
   this.pushdata()
  },
 
  methods: {
    edit(row){
      console.log(row);
      this.updatetable.id=row.id
      this.updatetable.pointname=row.name
      this.updateVisible=true

      console.log(this.updatetable);
    },
    del(row){
      var that = this;
      that.$axios
        .post("/api/admin/delArea", {
          "id":row.id,
        })
        .then(function (response) {
          that.$message.success({
            message: response.data.mes
          });
          that.dialogresetVisible=false
          that.newtable={}
          that.pushdata()
        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);

      if (val != 1) {
        var statcount = (val - 1) * this.req.pageCount
        this.req.pageIndex = statcount
        console.log("当前开始index--" + statcount);
      } else {
        this.req.pageIndex = 0
        console.log("当前开始index--" + statcount);
      }

      console.log(this.req);
      this.pushdata()
    },

    update(){
      var that = this;
      that.$axios
        .post("/api/admin/updateArea", {
          "id":that.updatetable.id,
          "name":that.updatetable.pointname,
        })
        .then(function (response) {
          that.$message.success({
            message: response.data.mes
          });
          that.updateVisible=false
          that.pushdata()
        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
    },



    addPoint(){
      var that = this;
      that.$axios
        .post("/api/admin/addArea", {
          "name":that.newtable.pointname,
        })
        .then(function (response) {
          that.$message.success({
            message: response.data.mes
          });
          that.dialogresetVisible=false
          that.newtable={}
          that.pushdata()
        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
    },
    pushdata(){
    var that = this;
      that.$axios
        .post("/api/admin/getArea", {
          "pageIndex": that.req.pageIndex,
          "pageCount": that.req.pageCount,
        })
        .then(function (response) {
          // that.$message.success({
          //   message: "数据已刷新~"
          // });
          that.Users = response.data.data

        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
   }
  },
}
</script>
<style scoped></style>
