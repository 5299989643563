
export function fileUploading(param) {
    
    const formData = new FormData();
    formData.append('file', param.file);

    const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
      this.$axios.post('/api/minio/uploadFile', formData, config)
        .then(res => {
          console.log(res)
          if (res.data.code === 200) {
           console.log("success");
          }
        })
        .catch(err => {
          console.log(err)
        })



    // try {
    //     const response = service({
    //         url: 'http://localhost:8080/minio/uploadFile',
    //         method: 'POST',
    //         data: formData,
    //         headers: { 'Content-type': 'multipart/form-data' }
    //     });
    //     return response;
    // } catch (error) {
    //     MessageBox({
    //         title: '提示',
    //         message: '文件上传失败，请稍后再试',
    //         type: 'error'
    //     });
    //     console.log(error);
    // }
}
