<template>
  <div class="context">

    <div class="cardheard">

<div style="margin-right: 15px; width:600px">
  <el-select  @change="changeName" v-model="areaId" placeholder="请选择校区">
        <el-option
          v-for="item in areas"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
  <el-input style="margin-right: 15px; width:100px"    v-model="money" placeholder="金额(元)"></el-input>
  <el-input style="margin-right: 15px; width:100px"   v-model="num" placeholder="数量"></el-input>  
  <el-button @click="addCoupon">发布</el-button>
  <el-button @click="clear">清空</el-button>
</div>

</div>
    <template>
      <el-table v-model="Users" :data="Users" style="width: 100%" height="80%">
        <el-table-column label="序号" type="index" width="50">
        </el-table-column>

        <el-table-column label="总金额">
          <template slot-scope="scope">
            <span>{{ scope.row.money }}</span>
          </template>
        </el-table-column>

        <el-table-column label="总数量">
          <template slot-scope="scope">
            <span>{{ scope.row.num }}</span>
          </template>
        </el-table-column>

        
        <el-table-column label="发放时间">
          <template slot-scope="scope">
            <span>{{ scope.row.time }}</span>
          </template>
        </el-table-column>

        <el-table-column label="剩余金额">
          <template slot-scope="scope">
            <span>{{ scope.row.surplusMoney }}</span>
          </template>
        </el-table-column>

        <el-table-column label="剩余数量">
          <template slot-scope="scope">
            <span>{{ scope.row.surplusNum }}</span>
          </template>
        </el-table-column>





        <el-table-column label="操作" width="300">
 
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="looknote(scope.$index, scope.row)" size="small">查看</el-button> -->
            <!-- <el-button type="text" @click="copyPhone(scope.row)" size="small">编辑</el-button> -->
            <el-button type="text"  @click="del(scope.row)" size="small">删除</el-button>
        </template>
        </el-table-column>
      </el-table>

      <div slot="footer" class="dialog-footer">
        <div class="block">
          <el-pagination @current-change="handleCurrentChange" background layout="prev, pager, next" :total="10000">
          </el-pagination>
        </div>
      </div>

      <el-dialog
      title="添加快递点"
      :visible.sync="dialogresetVisible"
      width="30%"
      center
    >
      <el-form :model="newtable">
        
        <el-input v-model="newtable.pointname" placeholder="快递点名称"></el-input>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogresetVisible = false">取 消</el-button>
        <el-button type="primary" @click="addPoint">确 定</el-button>
      </div>
    </el-dialog>



    </template>
    <template>
</template>
  </div>
</template>

<script>
import store from '@/store/'

export default {
  data() {
    return {
      areas:[],
      areaId:"",
      money:"",
      num: "",
      req: {
        "pageIndex": 0,
        "pageCount": 10
      },
      newtable:{
        pointname:"",
      },
      Users:[],
      dialogresetVisible:false
    }
  },
  mounted() {
    this.getArea()
   this.pushdata()
  },
 
  methods: {
    changeName(){
      console.log(112)
      this.pushdata();
    },
    getArea(){
    var that = this;
      that.$axios
        .post("/api/admin/getArea", {
          "pageIndex": that.req.pageIndex,
          "pageCount": that.req.pageCount,
        })
        .then(function (response) {
          // that.$message.success({
          //   message: "数据已刷新~"
          // });
          that.areas = response.data.data
          if(that.areas){
            that.areaName=that.areas[0].name
            console.log( that.areaName)
          }

        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
   },
    addCoupon(){
      if(this.money=="" ||this.num =="")
      {
        this.$message({
            message: "参数错误"
          });
          return
      }
      var that = this;
      that.$axios
        .post("/api/admin/addCoupon", {
          "money":that.money,
          "num":that.num,
          "areaId":that.areaId
        })
        .then(function (response) {
          that.$message.success({
            message: response.data.mes
          });
          that.pushdata()
        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "发布失败"
          });
        });
    },
    clear(){
      this.money=""
      this.num=""
    },
    del(row){
      var that = this;
      that.$axios
        .post("/api/admin/delCoupon", {
          "id":row.id,
        })
        .then(function (response) {
          that.$message.success({
            message: response.data.mes
          });
          that.dialogresetVisible=false
          that.newtable={}
          that.pushdata()
        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);

      if (val != 1) {
        var statcount = (val - 1) * this.req.pageCount
        this.req.pageIndex = statcount
        console.log("当前开始index--" + statcount);
      } else {
        this.req.pageIndex = 0
        console.log("当前开始index--" + statcount);
      }

      console.log(this.req);
      this.pushdata()
    },
    addPoint(){
      var that = this;
      that.$axios
        .post("/api/admin/addPoint", {
          "pointName":that.newtable.pointname,
        })
        .then(function (response) {
          that.$message.success({
            message: response.data.mes
          });
          that.dialogresetVisible=false
          that.newtable={}
          that.pushdata()
        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
    },
    pushdata(){
    var that = this;
      that.$axios
        .post("/api/admin/getAllCoupon", {
          "pageIndex": that.req.pageIndex,
          "pageCount": that.req.pageCount,
          "areaId":that.areaId
        })
        .then(function (response) {
          // that.$message.success({
          //   message: "数据已刷新~"
          // });
          that.Users = response.data.data

        })
        .catch(function (error) {
          console.log(error);
          that.$message({
            message: "刷新失败"
          });
        });
   }
  },
}
</script>
<style scoped></style>
