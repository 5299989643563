import Vue from 'vue'
// import { 
//     Button, 
//     Container,
//     Header,
//     Aside,
//     Main,
//     Menu,
//     Submenu,
//     MenuItem,
//     Card,
//     Carousel,
//     CarouselItem,
//     Tag,
//     DropdownMenu,
//     DropdownItem,
//     Dropdown
//  } from 'element-ui'

import ElementUI from 'element-ui'  //手动变红
// Vue.use(Button)
// Vue.use(Container)
// Vue.use(Header)
// Vue.use(Aside)
// Vue.use(Main)
// Vue.use(Menu)
// Vue.use(Submenu)
// Vue.use(MenuItem)
// Vue.use(Card)
// Vue.use(Carousel)
// Vue.use(CarouselItem)
// Vue.use(Tag)
// Vue.use(DropdownMenu)
// Vue.use(DropdownItem)
// Vue.use(Dropdown)
Vue.use(ElementUI)